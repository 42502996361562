import { CommonState, Fetcher } from '@primeit/components-vue';

const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};

const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      let json;

      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/tag`
        );

        if (response.ok) {
          json = await response.json();
        }

      } catch (e) {
        return {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      
      context.commit('POPULATE_ALL', json);
    },
    FETCH_DELETE: async (context, params) => {
      let json;

      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/tag/${params.tag_id}`,
          {
            method: 'DELETE',
          }
        );
        json = {
          ...(await response.json()),
          deleted: true,
        };
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_ONE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/tag/${params.tag_id}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        return {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },
    FETCH_SAVE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/tag`,
          {
            body: JSON.stringify(params.entityToSave),
            headers,
            method: 'POST',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/tag`,
          {
            body: JSON.stringify(params.entityToUpdate),
            headers,
            method: 'PUT',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_ALL: (state) => {
      return JSON.parse(JSON.stringify(state.entities));
    },
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
  },
  namespaced: true,
  state: {
    entities: [],
    entity: null,
  },
};
CommonState.registerModule('tag', module);
